@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("./assets/fonts/Aeonik-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

:root {
  --font-aeonik: "Aeonik";
}

html {
  @apply bg-tixy-1000;
}
